@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import 'pages/dashboard';

// iCheck
@import '~icheck/skins/all.css';

// SweetAlert2
@import "~sweetalert2/src/sweetalert2";
